#hsEmbeddedOverlay {
  background-color: #fff !important;
  opacity: 0 !important;
  z-index: -1 !important;
}

#hsEmbeddedFrame {
  box-shadow: none !important;
  border: none !important;
  -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 4s; /* Firefox < 16 */
  -ms-animation: fadein 4s; /* Internet Explorer */
  -o-animation: fadein 4s; /* Opera < 12.1 */
  animation: fadein 4s;
}

#hsEmbeddedWrapper {
  top: 61px !important;
  left: 0px !important;
  -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 4s; /* Firefox < 16 */
  -ms-animation: fadein 4s; /* Internet Explorer */
  -o-animation: fadein 4s; /* Opera < 12.1 */
  animation: fadein 4s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


div m-signer-mobile-header.nav.light.m-signature-request--header.small {
  box-shadow: none !important;
}

div.m-signer-mobile-header {
  box-shadow: none !important;
}

html .m-signer-mobile-header.nav {
  box-shadow: none !important;
}

.bg-cerulean, .hbg-cerulean:focus, .hbg-cerulean:hover {
  background-color: #ff3434 !important;
}

@media(min-width: 561px) {

#footer_instructions {
  display: inline-block;
}

#footer_buttons {
  display: inline-block;
  float: right;
  margin-top: 23px;
}
}

@media(min-width: 561px) {
  #main_footer_instructions {
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 5px
  }

  #sub_footer_instructions {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #8c8c8c;
  }
}

@media(max-width: 560px) {
  #main_footer_instructions {
    margin-top: 3px;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: center;
  }

  #sub_footer_instructions {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #8c8c8c;
    text-align: center;
  }

  #footer_instructions {
    width: 100%;
  }

  #footer_buttons {
    margin-top: 10px;
    text-align: center;
  }
}

.e-signature {
  position: absolute;
  font-size: 11px;
}

.e-textbox {
  position: absolute;
  font-size: 11px;
}

.clickable {
  cursor: pointer;
}

.red-border {
  border: 2px solid #ff3434;
}

.black-border {
  border: 1px solid #4e4e4e;
}

.e-signature-img, .e-initials-img, .e-textbox-img, e.checkbox-img {
  position: absolute;
}

.e-existing-signature-img, .e-existing-initials-img, .e-existing-textbox-img, e.existing-checkbox-img {
  position: absolute;
  border: 1px solid #4e4e4e;
}

.e-signature-img img, .e-existing-signature-img img{
  z-index: 1;
  background-size: 70px 25px;
  padding: 12px;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.e-initials-img img, .e-existing-initials-img img{
  z-index: 1;
  background-size: 25px 25px;
  background-color: white;
  padding: 12px;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.e-textbox-img img, .e-existing-textbox-img img{
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.e-checkbox-img img, .e-existing-checkbox-img img{
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

#e-document {
  transform-origin: left top 0px;
  overflow: scroll;
}

#signature-document {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  overflow: hidden;
  padding-top: 0px;
  transform-origin: left top 0px;
  background-color: #f7f7f7;
}

@media(min-width: 1130px) {
  #signature-document {
    width: 100%;
  }
}

#signature-document-inner {
  display: inline-block;
  position: relative;
  max-height: 100%;
  overflow: auto;
  background-color: #f7f7f7;
  max-width: 1090px;
  margin: auto;
}

.signature-document-page {
  text-align: center;
  border-bottom: 2px solid #e6e6e6;
}

.signature-document-page img{
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  background-color: white;
}

.signature-canvas {
  border: 1px solid #e6e6e6;
}

@media(min-width: 270px) {
  .inner-canvas-div {
    width: 95%;
    margin: 0 auto;
  }
}

@media(min-width: 271px) and (max-width: 300px) {
  .inner-canvas-div {
    width: 85%;
    margin: 0 auto;
  }
}

@media(min-width: 301px) and (max-width: 340px) {
  .inner-canvas-div {
    width: 75%;
    margin: 0 auto;
  }
}

@media(min-width: 341px) and (max-width: 400px) {
  .inner-canvas-div {
    width: 65%;
    margin: 0 auto;
  }
}

@media(min-width: 401px) {
  .inner-canvas-div {
    width: 50%;
    margin: 0 auto;
  }
}
